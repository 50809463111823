<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="pageIsLoading"
      variant="light"
      opacity="0.85"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <b-card

          class="text-center"
        >

          <h2>
            <b-spinner

              class="mr-1"
              label="Loading"
            />
            Loading...
          </h2>

        </b-card>
      </template>
      <b-container>
        <div class="text-center">
          <h1 class="mt-1">
            FAST INVOICE
          </h1>
        </div>
        <hr>
        <b-row>

          <b-col
            md="6"
            xl="4"
          >
            <h1 class="mb-2">
              {{ selectedPlan.title }} plan
            </h1>
            <b-list-group class="list-group-circle text-left">
              <b-list-group-item
                v-for="(data,index) in selectedPlan.planBenefits"
                :key="index"
              >
                <strong> {{ data }}</strong>
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col>
            <h2 class="mb-2">
              Order summary
            </h2>
            <h3 class="mb-1">
              FastInvoice
            </h3>
            <span>support@fastinvoice.me</span>
            <dl class="row mt-3">
              <dt class="col-sm-8">
                <h2 class="font-weight-bolder">
                  {{ selectedPlan.title }} (monthly)
                </h2>
              </dt><dd class="col-sm-4 text-right ">
                <h2 class="font-weight-bolder">
                  USD 15.00
                </h2>
              </dd>
            </dl>
            <hr>
            <h3 class="mb-2">
              Payment method
            </h3>
            <status-alert
              :is-success="isSuccess"
              :error-data="errorData"
              :success-msg="$t('Succesful')"
            />
            <div v-if="!isSuccess">

              <card-manager
                ref="cardManager"
                @cardAddError="handleCardAddError"
                @dataFetched="cardDataFetched"
                @cardAdded="newCardAdded"
              />
              <p class="mt-2">
                By clicking on Buy, I agree to the Terms of Service, Privacy Policy and Billing Policy.
              </p>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mt-2"
                size="lg"
                variant="primary"

                @click="submit"
              >
                Buy {{ selectedPlan.title }} plan
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay></div>
</template>

<script>
import {
  BRow, BCol, BContainer, BListGroup, BListGroupItem, BButton, BOverlay, BCard, BSpinner,
} from 'bootstrap-vue'

import store from '@/store'
import cardManager from './CardManager.vue'

export default {
  components: {
    BRow, BCol, BContainer, BListGroup, BListGroupItem, BButton, BOverlay, BCard, BSpinner, cardManager,
  },
  props: {
    selectedPlan: {
      type: Object,
      required: true,
    },
    paymentPeriod: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errorData: null,
      isSuccess: false,
      token: null,
      pageIsLoading: true,
      currentCard: null,
      currentCartExist: false,
    }
  },
  computed: {

  },
  watch: {
    currentCard(val) {
      if (val == null) {
        this.currentCartExist = true
      }
    },
  },
  methods: {
    cardDataFetched(currentCard) {
      this.currentCard = currentCard
      this.pageIsLoading = false
    },
    handleCardAddError() {
      this.pageIsLoading = false
    },
    newCardAdded(currentCard) {
      this.currentCard = currentCard

      this.buyPlan()
    },
    submit() {
      this.token = null
      this.pageIsLoading = true
      if (this.currentCard != null) {
        this.buyPlan()
      } else {
        // this will trigger the process
        this.$refs.cardManager.getCardToken()
      }
    },

    buyPlan() {
      let tokenId = null
      if (this.token) {
        tokenId = this.token.id
      }
      store.dispatch('app-account/subscribePlan', { token: tokenId, packet_id: this.selectedPlan.id, payment_period: this.paymentPeriod })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
        //  console.log(response)
          this.pageIsLoading = false
          this.errorData = null
          this.$store.state.paymentCompleted = true
          this.$router.push({ path: '/settings#manage-subscription' })
        })
        .catch(error => {
          this.pageIsLoading = false
          this.isSuccess = false
          this.errorData = error.response.data
        })
    },

  },
}
</script>
<style>
.b-sidebar > .b-sidebar-header .close {
    font-size: 5.5rem;
}
</style>
